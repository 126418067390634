import { getCookie } from '../shared/helpers';
let observer = {};

export const setObserver = (_observer) => {
  observer = _observer;
};

export const ALERT_EXPIRE_SESSION = {
  es_ES: 'Su sesión ha caducado',
  en_US: 'Your session has expired',
  pt_BR: 'Sua sessão expirou',
};

export const unauthorized = (error) => {
  // TODO: will be redirect to expired session page and logout execute.
  observer.actions.alert.showError(ALERT_EXPIRE_SESSION[getCookie('idiom')]);
  if (
    error &&
    error.response &&
    error.response.error_code === 'invalid_refresh_token'
  ) {
    window.location.href = '/login';
  } else {
    window.location.href = '/logoff';
  }
};

export const statusReaction = {
  401: unauthorized,
};

export const errorHandler = async (request) => {
  try {
    const response = await request;
    return response;
  } catch (error) {
    if (error.response) {
      if (statusReaction[error.response.status]) {
        statusReaction[error.response.status](error);
      } else throw error;
    } else {
      observer.actions.alert.showError('UNEXPECTED_ERROR_OCCURRED');
    }
  }
};

export const TERMS = {
  es_ES: `Las presentes condiciones se aplicarán a la prestación por parte de Teladoc Health International del servicio Doctor Please! a aquellos miembros que se registren
    para el uso del portal web electrónica. Lea atentamente las presentes condiciones, y asegúrese de entenderlas. Se le pedirá que acepte estas condiciones antes
    de registrarse en el portal web. La aceptación de las mismas es condición indispensable para el uso del servicio Doctor Please! Lea las siguientes condiciones
    antes de registrarse en el servicio Doctor Please!`,
  en_US: `These terms and conditions will apply to the provision by Teladoc Health International of the
    Doctor Please! service to those members who register for the use of the electronic Web portal.
    Please read these terms and conditions carefully and make sure that you understand them. You
    will be asked to agree these terms and conditions before registering on the Web portal.
    Acceptance of these terms and conditions is a condition of use for the Doctor Please! service.
    Please read the following Terms and Conditions prior to registering for the Doctor Please!
    service.`,
  pt_BR: `Estes termos e condições serão válidos para o fornecimento da Teladoc Health International do serviço Doctor Please! aos membros que se registrarem para uso
    no Aplicativo eletrônico. Leia estes termos e condições com atenção e confirme se os compreendeu. Você será solicitado a concordar com estes termos e
    condições antes de se registrar no Aplicativo. Aceitar estes termos e condições é uma condição para o uso do serviço Doctor Please! Leia estes Termos e
    Condições antes de se registrar no serviço Doctor Please!`,
  fr_FR: `Les présentes conditions s’appliqueront à la mise à disposition, par Teladoc Health International,
    du service Doctor Please! aux membres qui s’inscrivent pour utiliser le portail web électronique.
    Veuillez lire les présentes conditions avec attention et vous assurer que vous les comprenez. Il
    vous sera demandé d’accepter les présentes conditions avant de vous inscrire sur le portail web.
    L’utilisation du service Doctor Please! est subordonnée à l’acceptation des présentes conditions.
    Veuillez lire les Conditions suivantes avant de vous inscrire au service Doctor Please!`,
  de_DE: `Diese Bedingungen gelten für die Bereitstellung des Dienstes Doctor Please! durch Teladoc Health International an die Mitglieder, die sich für die Nutzung der
    elektronischen Web-portal registrieren. Bitte lesen Sie diese Bedingungen sorgfältig und stellen Sie sicher, dass Sie sie verstanden haben. Sie werden aufgefordert,
    diese Bedingungen zu bestätigen, bevor Sie sich in der Web-portal registrieren. Die Annahme dieser Bedingungen ist die Voraussetzung für die Nutzung von Doctor
    Please! Bitte lesen Sie die folgenden Bedingungen, bevor Sie sich für Doctor Please! registrieren.`,
  it_IT: `Le presenti condizioni generali si applicano alla fornitura del servizio Doctor Please! da parte di
    Teladoc Health International ai membri che si registrano per l’utilizzo dell’App elettronica. La
    preghiamo di leggere attentamente le presenti condizioni generali e assicurarsi di comprenderle.
    Le sarà chiesto di accettare le presenti condizioni generali prima di registrarsi sull’App. L’utilizzo
    del servizio Doctor Please! è condizionato all’accettazione delle presenti condizioni generali. La
    preghiamo di leggere le seguenti condizioni generali prima di registrarsi per il servizio Doctor
    Please!`,
};

export const TERMS_LIST = {
  es_ES: [
    `Las presentes condiciones de servicio se aplican al uso del portal web electrónica. El objetivo del portal web es facilitar la comunicación rutinaria con nuestro
        equipo médico, para casos que no sean urgencias, y proporcionarle un acceso rápido y seguro al mismo. Siga estas directrices y póngase en contacto con
        nosotros si tiene alguna duda.`,
    `En caso de urgencia médica, no utilice el portal web para comunicarse con nosotros. Marque el número de urgencias locales correspondiente al país en el que
        se encuentre y, a continuación, póngase en contacto con su médico.`,
    `Al utilizar el portal web y este sitio web, confirma tener más de 18 años. Los padres y tutores legales pueden utilizar el portal web para sus hijos menores de
        18 años; sin embargo, la comunicación en el portal web será con la madre, el padre o el tutor legal de la persona en cuestión.`,
    `Se compromete a no utilizar el portal web para ningún otro propósito distinto del de comunicarse con nosotros sobre su caso. Se compromete a no intentar
        eludir ninguna de las salvaguardas de seguridad que utilizamos para proteger la seguridad de nuestros sistemas de información.`,
    `Al registrarse nos proporcionará su dirección de correo electrónico de preferencia. Recibirá un correo electrónico con las credenciales de acceso a el portal
        web.`,
    `Recibirá un correo electrónico o una llamada telefónica cuando haya concertado una cita a través del portal web. El correo electrónico no contendrá
        información confidencial sobre salud, pero le pedirá que inicie sesión en el portal web.`,
    `Si actúa en nombre de otra persona y decide compartir con nosotros cualquiera de sus datos de contacto, deberá verificar que cuenta con un consentimiento
        inequívoco de esa persona para hacerlo, y obtendremos los permisos pertinentes a la mayor brevedad posible.`,
    `El portal web tiene salvaguardas destinadas a impedir que partes no autorizadas puedan acceder a los mensajes o leerlos durante su envío. Sin embargo, la
        seguridad de los mensajes depende de dos factores importantes. En primer lugar, necesitamos que se asegure de que tenemos su correo electrónico y datos de
        contacto correctos y debe informarnos en caso de que estos cambien; y, en segundo lugar, debe mantener su nombre de usuario y contraseña del portal web en
        la más estricta confidencialidad y no compartirlos con nadie. Si cree que alguien conoce su contraseña, debe notificárnoslo y, a continuación, iniciar sesión a la
        mayor brevedad en el portal web para cambiarlos.`,
    `Los mensajes y archivos enviados y recibidos a través del portal web pueden ser incluidos en nuestros registros y eliminarse de acuerdo con nuestra política
        de mantenimiento de registros.`,
    `El funcionamiento del portal web puede verse interrumpido por problemas de hardware o software informáticos, interrupciones en los servicios de Internet,
        virus informáticos u otros problemas que escapen a nuestro control. Si precisa ponerse en contacto con nosotros en caso de interrupción del servicio, puede
        hacerlo a través del número de teléfono que figura en la sección de contacto de la página web de Teladoc Health International o directamente en nuestra sede
        europea en el teléfono +34 932 540 010.`,
    `No utilice su cuenta de correo electrónico habitual para enviarnos información confidencial (como sus datos de salud), ya que este medio no es
        suficientemente seguro. Solo debe proporcionar sus datos de salud a Teladoc Health International a través del portal web.`,
    `Cualquier actualización de las presentes condiciones se mostrará en el portal web tan pronto como sea razonablemente posible. Podemos modificar,
        interrumpir o suspender el servicio del portal web por cualquier motivo en cualquier momento y sin necesidad de notificárselo previamente.`,
    `Todo el material con derechos de autor contenido en esta aplicación es propiedad de Teladoc Health International. Cualquier acceso a este material por su
        parte no le otorga ninguna licencia o derecho de reproducción o distribución, y no debe realizar estas acciones sin el consentimiento previo, explícito y por
        escrito de Teladoc Health International. De lo contrario, puede incurrir en un delito grave contra la propiedad intelectual e industrial.`,
    `Debe comprobar si tiene derecho a acceder a nuestros servicios en el territorio en el que se encuentra. Prestamos nuestros servicios en cumplimiento de las
        leyes de protección de datos de la UE.`,
    `A los efectos de este servicio, su derecho a utilizarlo es un beneficio de la contratación de su póliza y puede ejercerlo incluso si tiene alguna condición pre-
        existente. Sin embargo, este servicio no constituye confirmación de cobertura para este evento o cualquier costo futuro incurrido y no estará cubierto por el
        costo del tratamiento para condiciones preexistentes si su póliza no proporciona cobertura, llame a sus aseguradoras primero en caso de que se requiere más
        atención médica y le brindarán la ayuda y la asistencia que necesita.`,
    `Los médicos de Teladoc Health International pueden recetar medicamentos solo cuando, según su criterio profesional, lo mejor para usted sea recibir el
        medicamento de acuerdo con la política de recetas de la compañía.`,
  ],
  en_US: [
    'These terms of service apply to the use of the electronic Web portal. The purpose of the Web portal is to make routine, non-emergency communication more convenient and to provide you with fast and secure access to our doctors. Please follow these guidelines and contact us if you have any questions.',
    'In case of a medical emergency, do not use the Web portal to communicate with us. Please dial the relevant local emergency number for the country you are in, and then contact your treating doctor.',
    'By using the Web portal and this site you confirm that you are older than 18 years of age. Parents and legal guardians can use the Web portal for their children under 18 years of age, however, communication in the Web portal will be with the parent or legal guardian of the relevant individual.',
    'You agree not to use the Web portal for any purpose other than to communicate with us about your case. You agree not to attempt to circumvent any security safeguard that we use to protect the security of our information systems.',
    'Upon registering you will provide us with your preferred email address. You will receive an e-mail with login credential to the Web portal.',
    'You will receive an email or phone call whenever you have scheduled an appointment through the Web portal. The e-mail will not contain confidential health information, but will prompt you to sign into the Web portal.',
    'If you are contacting on behalf of someone else and choose to share any of their contact information with us, you must verify that you have clear consent from that person to do so, and we will obtain appropriate consents at the earliest opportunity.',
    'The Web portal has safeguards that are intended to prevent unauthorized parties from being able to access or read messages while they are in transmission. However, keeping messages secure depends on two important factors. First, we need you to make sure we have your correct email and contact data and you must inform us if it ever changes, and second, you should keep your Web portal username and password confidential and not share with others. If you think someone knows your password, you should notify us and then you should promptly log into the Web portal and change it. Messages and files sent and received through the Web portal may be included in our records, and may be deleted in accordance with our records retention policy.',
    'The operation of the Web portal may be interrupted by problems with computer hardware or software, interruptions in internet services, computer viruses or other problems beyond our control. If you need to reach us in the event of a service outage, you can contact us through the phone number in the contact us section of the Teladoc Health International’s website or directly to our European Headquarters +34 932 540 010.',
    'Please do not use your regular email account to send us confidential information (such as your health details) since regular email is not sufficiently secure. You should only provide your health data to Teladoc Health International through the Web portal. 12. Any update of these Terms and Conditions will be displayed within the Web portal as soon as reasonably practicable. We may modify, discontinue, or suspend the Web portal service for any reason at any time and without any prior notification to you.13. All copyright material contained within this Web portal is the property of Teladoc Health International. Any access to this material by you does not entitle you to any license or right for the reproduction and/or distribution and you must not do so without explicit, prior written consent from Teladoc Health International, which may constitute a serious offence of intellectual and industrial property.',
    'You should check whether you are entitled to access our services in the territory where you are. We provide our services in compliance with EU Data Protection laws.',
    'For the purpose of this service, your right to use the service is a benefit of purchasing your policy and you can use this service even if you have a pre-existing condition. However this service does not constitute confirmation of coverage for this event or any future costs incurred and you will not be covered for the cost of treatment for pre-existing conditions if your policy does not provide cover, please call your insurers first in the event of further medical attention being required and they will provide you with the help and assistance you need.',
    'The Teladoc Health International doctors may issue prescriptions for medicines only when in their professional judgment it is in your best interests to receive the medicine in accordance with the Teladoc Health International prescription policy.',
    'You should check whether you are entitled to access our services in the territory where you are. We provide our services in compliance with EU Data Protection laws.',
    'For the purpose of this service, your right to use the service is a benefit of purchasing your policy and you can use this service even if you have a pre-existing condition. However this service does not constitute confirmation of coverage for this event or any future costs incurred and you will not be covered for the cost of treatment for pre-existing conditions if your policy does not provide cover, please call your insurers first in the event of further medical attention being required and they will provide you with the help and assistance you need.',
    'The Teladoc Health International doctors may issue prescriptions for medicines only when in their professional judgment it is in your best interests to receive the medicine in accordance with the Teladoc Health International prescription policy.',
  ],
  pt_BR: [
    'Estes termos de serviço são válidos para uso do Aplicativo eletrônico. A finalidade do Aplicativo é tornar a comunicação de rotina e não emergencial mais conveniente e dar a você acesso rápido e seguro aos nossos médicos. Siga estas orientações e entre em contato conosco se tiver quaisquer dúvidas.',
    'Em caso de emergência médica, não use o Aplicativo para falar conosco. Ligue para o número de emergência local relevante do país onde está e então entre em contato com seu médico.',
    'Ao usar o Aplicativo e este site você confirma que tem mais de 18 anos de idade. Pais e tutores legais podem usar o Aplicativo para seus filhos com menos de 18 anos de idade, no entanto, as comunicações no Aplicativo serão com os pais ou com o(a) tutor(a) legal da pessoa envolvida.',
    'Você concorda em não usar o Aplicativo para nenhuma finalidade além de se comunicar conosco sobre seu caso. Você concorda em não tentar burlar a proteção de segurança que usamos para proteger a segurança dos nossos sistemas de informação.',
    'Ao se registrar conosco, você nos informará seu endereço de e-mail preferencial. Você receberá um e-mail com as credenciais de acesso ao Aplicativo.',
    'Você receberá um e-mail ou uma ligação sempre que tiver marcado uma consulta pelo Aplicativo. O e-mail não conterá informações de saúde confidenciais, mas pedirá que você acesse o Aplicativo.',
    'Se estiver entrando em contato em nome de outra pessoa e escolher compartilhar qualquer informação de contato dela conosco, verifique se tem o consentimento claro desta pessoa para fazer isso, e obteremos os consentimentos apropriados na oportunidade mais breve possível.',
    'O Aplicativo tem proteções que são destinadas a evitar que partes não autorizadas possam acessar ou ler mensagens enquanto elas estão em transmissão. Contudo, manter mensagens seguras depende de dois fatores importantes. Primeiro, precisamos que você verifique se temos seu e-mail e dados de contato corretos e você deve nos informar se houver alterações nesses dados. Segundo, você deve manter confidencial e não compartilhar com os outros o nome de usuário e a senha do Aplicativo. Se você achar que alguém sabe sua senha, nos avise e então acesse imediatamente o Aplicativo e a altere.',
    'Mensagens e arquivos enviados e recebidos pelo Aplicativo podem incluir seus registros e podem ser excluídos de acordo com nossa política de retenção de registros.',
    'As operações do Aplicativo podem ser interrompidas por problemas com o hardware ou o software do computador, interrupções em serviços de internet, vírus de computador ou outros problemas além do nosso controle. Se precisar falar conosco em caso de interrupção do serviço, entre em contato conosco pelo número de telefone na seção Fale conosco no site da Teladoc Health International ou diretamente com nossa sede europeia no número +34 932 540 010.',
    'Não use sua conta de e-mail comum para nos enviar informações confidenciais (como suas informações de saúde), pois o e-mail comum não é suficientemente seguro. Você deve enviar dados pessoais à Teladoc Health International somente pelo Aplicativo.',
    'Quaisquer atualizações a estes Termos e Condições serão mostrados no Aplicativo assim que razoavelmente possível. Podemos modificar, interromper ou suspender o serviço do Aplicativo por qualquer motivo, a qualquer momento e sem notificação prévia a você.',
    'Todo o material com direitos autorais contido neste Aplicativo é de propriedade da Teladoc Health International. Qualquer acesso a este material por você não lhe dá o direito a nenhuma licença ou direito para reprodução e/ou distribuição e você não deve fazer isso sem o prévio consentimento explícito e por escrito da Teladoc Health International, o que pode equivaler a um crime grave de propriedade intelectual e industrial.',
    'Verifique se você tem direito de acessar nossos serviços no território onde está. Fornecemos nossos serviços de acordo com as leis de Proteção de Dados da União Europeia.',
    'Para os fins deste serviços, seu direito de usar o serviço é um benefício da compra da nossa apólice e você pode usar este serviço mesmo que tenha uma condição preexistente. Contudo, este serviço não constitui confirmação de cobertura para este evento ou quaisquer custos futuros incorridos e você não será coberto(a) pelo custo do tratamento de condições preexistentes se a sua apólice não tiver cobertura. Em caso de necessidade de mais atenção médica, ligue para a seguradora primeiro, e eles prestarão a você a ajuda e a assistência de que precisa.',
    'Os médicos da Teladoc Health International podem prescrever medicamentos somente quando a seu critério profissional e é no seu melhor interesse receber o medicamento de acordo com a política de prescrição da Teladoc Health International.',
  ],
  fr_FR: [
    `Les présentes conditions de service s’appliquent à l’utilisation du portail web électronique.
    Le portail web a pour objet de faciliter les communications ordinaires et non urgentes et de
    vous permettre de bénéficier d’un accès rapide et sécurisé à nos médecins. Veuillez suivre
    ces directives et nous contacter si vous avez des questions.`,
    `En cas d’urgence médicale, veuillez ne pas utiliser le portail web pour communiquer avec
    nous. Veuillez composer le numéro des services d’urgence locaux du pays dans lequel vous
    vous trouvez puis contacter votre médecin traitant.`,
    `En utilisant le portail web et ce site, vous confirmez être âgé de plus de 18 ans.
    Les parents et tuteurs légaux peuvent utiliser le portail web pour leurs enfants âgés de moins
    de 18 ans, toutefois la communication via le portail web interviendra avec le parent ou le
    tuteur légal de la personne concernée.`,
    `Vous vous engagez à n’utiliser le portail web à aucune autre fin que la communication avec
    nous à propos de votre situation.
    Vous vous interdisez de tenter de contourner tout dispositif de sécurité que nous utilisons
    pour garantir la sécurité de nos systèmes informatiques.`,
    `Lors de votre inscription, vous nous communiquerez votre adresse e-mail préférée. Vous
    recevrez un e-mail avec vos identifiants de connexion au portail web.`,
    `Vous recevrez un e-mail ou un appel téléphonique chaque fois que vous programmerez un
    rendez-vous par le biais du portail web. Cet e-mail ne contiendra aucune information
    médicale confidentielle, mais vous invitera à vous connecter au portail web.`,
    `Si vous communiquez avec nous pour le compte d’une autre personne et décidez de nous
    transmettre ses coordonnées, vous devrez vous assurer d’avoir obtenu le consentement
    explicite de cette personne à cet effet et nous devrons obtenir les consentements
    appropriés dès que possible.`,
    `Le portail web contient des dispositifs de sécurité destinés à éviter que des parties non
    autorisées puissent accéder aux messages ou les lire lors de leur transmission. Toutefois, la
    sécurité des messages dépend de deux facteurs essentiels. Premièrement, nous avons
    besoin que vous vous assuriez que l’adresse e-mail et les coordonnées que vous nous avez
    communiquées sont exactes et vous devez nous informer de tout changement de cette
    adresse et de ces coordonnées. Deuxièmement, vous devez préserver la confidentialité de
    votre nom d’utilisateur et de votre mot de passe pour le portail web et vous abstenir de les
    communiquer à d’autres personnes. Si vous pensez que quelqu’un connaît votre mot de
    passe, vous devez nous en informer et vous connecter rapidement au portail web pour le
    modifier.`,
    `Les messages et fichiers envoyés et reçus via le portail web pourront être enregistrés dans
    nos registres et pourront être supprimés conformément à notre politique en matière de
    conservation de registres.`,
    `Le fonctionnement du portail web pourra être interrompu en cas de problèmes affectant du
    matériel informatique ou des logiciels, d’interruption des services Internet, de virus
    informatiques ou d’autres problèmes échappant à notre contrôle.
    Si vous avez besoin de nous contacter lors d’une interruption du service, vous pouvez le faire
    en composant le numéro de téléphone indiqué dans la partie « nous contacter » du site
    Internet Teladoc Health International ou en contactant directement notre siège européen
    au +34 932 540 010.`,
    `Veuillez ne pas utiliser votre compte de messagerie habituel pour nous envoyer des
    informations confidentielles (telles que des données relatives à votre santé) car les e-mails
    ordinaires ne sont pas suffisamment sécurisés. Vous ne devez communiquer de données
    relatives à votre santé à Teladoc Health International que par le biais du portail web.`,
    `Toute version mise à jour des présentes Conditions sera affichée dans le portail web dès que
    possible. Nous pourrons modifier, arrêter ou suspendre le service du portail web pour
    quelque raison que ce soit, à tout moment, sans vous adresser de préavis.`,
    `Tous les éléments protégés par le droit d’auteur contenus dans cette Application sont la
    propriété de Teladoc Health International. Aucun accès à ces éléments ne vous conférera
    une quelconque licence ou un quelconque droit de reproduction et/ou de diffusion et vous
    ne devrez pas les reproduire ou les diffuser sans le consentement écrit préalable explicite
    de Teladoc Health International, en l’absence duquel une telle reproduction ou diffusion
    constituerait une violation grave de propriété intellectuelle et industrielle.`,
    `Vous devez vérifier que vous êtes autorisé à accéder à nos services sur le territoire sur lequel
    vous vous trouvez.`,
    `Nous fournissons nos services dans le respect de la législation de l’UE en matière de
    protection des données. Aux fins de ce service, votre droit d’utiliser le service est un
    avantage lié à la souscription de votre police et vous pouvez utiliser ce service même si vous
    avez déjà un problème de santé. Toutefois, ce service ne constitue pas une confirmation de
    couverture au titre de ce problème ou de tous coûts que vous pourriez engager à l’avenir et
    le coût du traitement de vos problèmes de santé antérieurs ne sera pas couvert si votre
    police ne le prévoit pas. Veuillez contacter vos assureurs en premier lieu si d’autres soins
    médicaux sont nécessaires afin qu’ils vous apportent l’aide et l’assistance dont vous avez
    besoin.`,
    `Les médecins Teladoc Health International ne peuvent délivrer des ordonnances pour des
    médicaments que si, exerçant leur jugement professionnel, ils estiment qu’il est dans votre
    intérêt de prendre les médicaments, conformément à la politique en matière de
    prescription de Teladoc Health International.`,
  ],
  de_DE: [
    `Diese Nutzungsbedingungen gelten für die Nutzung der elektronischen Web-portal. Der Zweck dieses Web-portal ist es, routinemäßige, nicht akute
    Kommunikation bequemer zu machen und Ihnen einen schnellen und sicheren Kontakt zu unseren Ärzten zu ermöglichen. Bitte befolgen Sie diese Richtlinien
    und setzen Sie sich bei Fragen mit uns in Verbindung.`,
    `Nutzen Sie im medizinischen Notfall nicht das Web-portal, um sich mit uns in Verbindung zu setzen. Bitte wählen Sie stattdessen die entsprechende lokale
Notrufnummer des Landes, in dem Sie sich aufhalten, und wenden Sie sich anschließend an Ihren behandelnden Arzt.`,
    `Durch die Nutzung dieses Web-portal und dieser Website bestätigen Sie, dass Sie älter als 18 Jahre sind. Eltern und Erziehungsberechtigte können das Web-
portal für ihre Kinder unter 18 Jahren nutzen, aber die Kommunikation in der App erfolgt mit dem Elternteil oder dem Erziehungsberechtigten der betreffenden
Person. Sie bestätigen, das Web-portal nicht für andere Zwecke zu nutzen, außer zur Kommunikation mit uns über Ihren Fall. Sie stimmen zu, dass Sie nicht
versuchen, Sicherheitsvorkehrungen zu umgehen, die wir zum Schutz unserer IT-Systeme nutzen.`,
    `Bei der Registrierung übermitteln Sie uns Ihre bevorzugte E-Mail-Adresse. Sie erhalten eine E-Mail mit den Anmeldedaten für das Web-portal.`,
    `Sie erhalten eine E-Mail oder einen Anruf, wenn Sie über das Web-portal einen Termin vereinbart haben. Die E-Mail enthält keine vertraulichen
Gesundheitsdaten, sondern fordert Sie auf, sich in der App anzumelden.`,
    `Wenn Sie den Kontakt im Namen einer anderen Person herstellen und entscheiden, uns deren Kontaktdaten zu übermitteln, müssen Sie sichergehen, dass die
Person ihre ausdrückliche Einwilligung hierfür erteilt hat, und wir bemühen uns schnellstmöglich um die Einholung der entsprechenden Einwilligung.`,
    `Das Web-portal verfügt über Sicherheitsvorkehrungen, die verhindern sollen, dass unbefugte Personen Zugang zu Nachrichten erhalten oder Nachrichten
während der Übermittlung lesen können. Allerdings hängt die kontinuierliche Sicherheit der Nachrichten von zwei wesentlichen Faktoren ab. Erstens sollten Sie
sicherstellen, dass wir Ihre korrekte E-Mail-Adresse und Kontaktdaten haben, und uns informieren, wenn sich diese ändern. Zweitens sollten Sie Ihren
Benutzernamen und Ihr Passwort für das Web-portal geheim halten und nicht an andere Personen weitergeben. Wenn Sie glauben, dass eine andere Person Ihr
Passwort kennt, sollten Sie sich mit uns in Verbindung setzen und sich anschließend umgehend in Ihrem Konto anmelden und Ihr Passwort ändern.`,
    `Über das Web-portal gesendete und empfangene Nachrichten und Dateien werden gegebenenfalls von uns aufbewahrt und können entsprechend unseren
Richtlinien zur Aufbewahrung von Unterlagen gelöscht werden.`,
    `Der Betrieb der App kann durch Probleme mit Computerhardware oder -software, Internetausfälle, Computerviren oder andere Probleme, die außerhalb
unseres Einflussbereichs liegen, unterbrochen werden. Wenn Sie sich im Falle eines Ausfalls des Dienstes mit uns in Verbindung setzen wollen, erreichen Sie uns
unter der Telefonnummer im Kontaktbereich der Website von Teladoc Health International oder direkt an unserem europäischen Hauptsitz unter +34 932 540
010.`,
    `Bitte nutzen Sie nicht Ihr normales E-Mail-Konto, um uns vertrauliche Informationen (wie Ihre Gesundheitsdaten) zu übermitteln, da normale E-Mails nicht
sicher genug für solche Zwecke sind. Sie sollten Ihre Gesundheitsdaten nur über das Web-portal an Teladoc Health International übermitteln.`,
    `Sämtliche Aktualisierungen dieser Bedingungen werden so schnell, wie es vernünftig und praktikabel ist, in der App angezeigt. Wir könnten den App-Dienst
jederzeit, aus jedwedem Grund und ohne vorherige Ankündigung ändern, einstellen oder aussetzen.`,
    `Sämtliche urheberrechtlich geschützte Materialien in dieses Web-portalsind Eigentum von Teladoc Health International. Durch den Zugriff auf dieses
Material erhalten Sie weder eine Lizenz noch ein Recht zur Vervielfältigung und/oder Verbreitung, und Sie dürfen dies nicht tun, ohne vorher eine ausdrückliche
schriftliche Genehmigung von Teladoc Health International eingeholt zu haben, da dies ansonsten eine schwerwiegende Verletzung des Rechts auf geistiges und
gewerbliches Eigentum darstellen kann.`,
    `Sie sollten überprüfen, ob Sie in dem Land, in dem Sie sich aufhalten, zur Nutzung unserer Dienstleistungen berechtigt sind. Wir stellen unsere
Dienstleistungen in Übereinstimmung mit den EU-Datenschutzgesetzen bereit.`,
    `Für die Zwecke dieses Dienstes ist Ihr Recht zur Nutzung dieses Dienstes eine Leistung durch den Abschluss Ihrer Versicherung, und Sie können diesen Dienst
auch nutzen, wenn Sie bereits an einer Vorerkrankung leiden. Dieser Dienst stellt jedoch keine Deckungszusage für dieses Ereignis oder für zukünftig anfallende
Kosten dar, und es besteht keine Deckung für die Kosten der Behandlung von Vorerkrankungen, wenn Ihre Versicherung diese nicht abdeckt. Bitte rufen Sie
zuerst Ihre Versicherung an, falls Sie weitere medizinische Versorgung benötigen; sie wird für die erforderliche Hilfe und Unterstützung sorgen.`,
    `Die Ärzte von Teladoc Health International dürfen nur dann Rezepte für Medikamente ausstellen, wenn es nach ihrem fachlichen Urteil in Ihrem besten
Interesse liegt, das Medikament im Einklang mit den Verschreibungsrichtlinien von Teladoc Health International zu erhalten.`,
  ],
  it_IT: [
    `Le presenti condizioni generali di servizio si applicano all’utilizzo dell’App elettronica.
    Lo scopo dell’App è agevolare le comunicazioni di routine e non urgenti e offrire un
    accesso rapido e sicuro ai nostri medici. La preghiamo di seguire queste linee guida e
    contattarci in caso di domande.`,
    `In caso di emergenza medica, non utilizzi l’App per comunicare con noi. Telefoni al
numero locale per le emergenze del Paese in cui si trova e poi contatti il suo medico
curante.`,
    `Utilizzando l’App e questo sito Lei conferma di avere più di 18 anni di età. I genitori e i
tutori legali possono utilizzare l’App per i propri figli di età inferiore a 18 anni; tuttavia,
la comunicazione nell’App avviene con il genitore o il tutore legale della persona
interessata.`,
    `Lei accetta di non utilizzare l’App per scopi diversi da quelli di comunicare con noi in
merito al suo caso. Lei concorda di non tentare di aggirare le misure di sicurezza che
utilizziamo per proteggere i nostri sistemi informatici.`,
    `Al momento della registrazione ci fornirà il suo indirizzo e-mail preferito. Riceverà
un’e-mail con le credenziali di accesso all’App.`,
    `Riceverà un’e-mail o una telefonata ogni volta che programma un appuntamento
tramite l’App. L’e-mail non conterrà informazioni riservate sulla salute, ma le chiederà
di accedere all’App.`,
    `Se prende contatto con noi per conto di un’altra persona e sceglie di condividere con
noi le sue informazioni di contatto, è necessario verificare di avere un chiaro consenso
da parte di quella persona prima di farlo; raccoglieremo gli opportuni consensi alla
prima occasione utile.`,
    `L’App dispone di misure di sicurezza volte a evitare che parti non autorizzate possano
accedere o leggere i messaggi durante la trasmissione. Tuttavia, tenere sicuri i
messaggi dipende da due fattori importanti. In primo luogo, è necessario che lei ci
comunichi correttamente il suo indirizzo e-mail e gli altri suoi dati di contatto e ci
informi di eventuali cambiamenti; in secondo luogo, è necessario che Lei mantenga
riservati il nome utente e la password dell’App e non li condivida con altri. Se ritiene
che qualcuno sia a conoscenza della sua password, ci avvisi e poi acceda
tempestivamente all'App per modificarla.`,
    `Messaggi e file inviati e ricevuti tramite l’App possono essere inclusi nei nostri archivi e
cancellati in conformità alla nostra politica di conservazione dei dati.`,
    `Il funzionamento dell’App può essere interrotto per problemi relativi a hardware o
software, interruzioni nei servizi Internet, virus informatici o altri problemi al di fuori
del nostro controllo. Se deve contattarci in caso di interruzione del servizio, può
telefonare al numero indicato nella sezione di contatto del sito internet di Teladoc Health International o direttamente presso la nostra sede centrale europea +34 932
540 010.`,
    `La preghiamo di non utilizzare il suo account e-mail ordinario per inviarci informazioni
riservate (come dettagli relativi alla sua salute) in quanto le e-mail tradizionali non
sono sufficientemente sicure. La preghiamo invece di fornire i dati sulla sua salute ad
Teladoc Health International solamente tramite l’App.`,
    `Qualsiasi aggiornamento delle presenti condizioni generali sarà visualizzato all’interno
dell’App non appena ragionevolmente possibile. Potremo modificare, interrompere o
sospendere il servizio dell’App per qualsiasi motivo in qualsiasi momento e senza
preavviso.`,
    `Tutto il materiale protetto da diritti d’autore contenuto in questa App è di proprietà di
Teladoc Health International. L’accesso a questo materiale da parte sua non le dà
diritto ad alcuna licenza o diritto di riproduzione e/o distribuzione; la riproduzione e/o
la distribuzione non possono essere effettuate senza il previo consenso scritto di
Teladoc Health International. In caso contrario potrebbero configurarsi come grave
reato contro la proprietà intellettuale e industriale.`,
    `La preghiamo di verificare di avere diritto di accedere ai nostri servizi nel territorio in
cui si trova. Forniamo i nostri servizi in conformità con la normativa UE in materia di
protezione dei dati.`,
    `La sottoscrizione della polizza dà il diritto ulteriore di utilizzare il servizio, fruibile anche
in caso di patologia preesistente. Tuttavia, questo servizio non costituisce conferma
della copertura per questo evento o per eventuali costi futuri sostenuti; Lei non sarà
quindi coperto per il costo del trattamento di patologie preesistenti se la sua polizza
non fornisce copertura. La preghiamo di contattare in primo luogo i suoi assicuratori in
caso di necessità di ulteriore assistenza medica: le forniranno l’aiuto e l’assistenza
necessari.`,
    `I medici di Teladoc Health International possono prescrivere medicinali solo quando
ritengono, secondo il loro giudizio professionale, che sia nel suo miglior interesse
ricevere il farmaco in conformità con la politica di prescrizione di Teladoc Health
International.s`,
  ],
};

export {
  VALIDATION_REQUIRED,
  LABEL_PT,
  LABEL_ES,
  LABEL_EN,
  LABEL_FR,
  LABEL_DE,
  LABEL_IT,
  TITLE_VIDEO,
  TITLE_PHONE,
  PREFFIX_CLIENT,
  PREFFIX_AGE,
  SON_OPTION,
  DAUGHTER_OPTION,
  OTHER_OPTION,
  VALIDATION_MUST_BE_UNDER_18,
  VALIDATION_MUST_BE_THAN_18,
  VALIDATION_VALID_DATE,
} from '../../shared/constants/locale';

export const SUBTITLE_NEED_HELP = {
  en_US: 'Who needs help today?',
  it_IT: 'Chi ha bisogno di aiuto oggi?',
  pt_BR: 'Quem precisa de ajuda hoje?',
  es_ES: '¿Quién necesita ayuda hoy?',
  de_DE: 'Wem dürfen wir heute helfen?',
  fr_FR: 'Pour qui est la consultation ?',
};

export const NEXT_BUTTON = {
  en_US: 'NEXT',
  it_IT: 'AVANTI',
  pt_BR: 'PRÓXIMO',
  es_ES: 'SIGUIENTE',
  de_DE: 'WEITER',
  fr_FR: 'SUIVANT',
};

export const BACK_BUTTON = {
  en_US: 'BACK',
  it_IT: 'PRECEDENTE',
  pt_BR: 'VOLTAR',
  es_ES: 'ATRÁS',
  de_DE: 'ZURÜCK',
  fr_FR: 'RETOUR',
};

export const FAMILY_MEMBER = {
  en_US: 'Family members',
  it_IT: 'Famigliare',
  pt_BR: 'Membros da família',
  es_ES: 'Miembros de la familia',
  de_DE: 'Familienmitglieder',
  fr_FR: 'Bénéficiaires',
};
/* Patient Form Intl */

export const FIRSTNAME_FIELD = {
  en_US: 'First Name',
  it_IT: 'Nome',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
  de_DE: 'Vorname',
  fr_FR: 'Prénom',
};

export const LASTNAME_FIELD = {
  en_US: 'Last Name',
  it_IT: 'Cognome',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellidos',
  de_DE: 'Nachname',
  fr_FR: 'Nom',
};

export const EMAILADDRESS_FIELD = {
  en_US: 'Email Address',
  it_IT: 'Indirizzo Email',
  pt_BR: 'Endereço de e-mail',
  es_ES: 'Dirección de correo electrónico',
  de_DE: 'Email Adresse',
  fr_FR: 'Adresse e-mail',
};

export const DATEOFBIRTH_FIELD = {
  en_US: 'Date of Birth',
  it_IT: 'Data di nascita',
  pt_BR: 'Data de nascimento',
  es_ES: 'Fecha de nacimiento',
  de_DE: 'Geburtsdatum',
  fr_FR: 'Date de naissance',
};

export const GENDER_FIELD = {
  en_US: 'Gender',
  it_IT: 'Sesso',
  pt_BR: 'Gênero',
  es_ES: 'Género',
  de_DE: 'Geschlecht',
  fr_FR: 'Civilité',
};

export const LANGUAGE_FIELD = {
  en_US: 'Language',
  it_IT: 'Lingua',
  pt_BR: 'Idioma',
  es_ES: 'idioma',
  de_DE: 'Sprache',
  fr_FR: 'Langue',
};

export const COUNTRY_RESIDENCE_FIELD = {
  en_US: 'Country of Residence',
  it_IT: 'Paese di residenza',
  pt_BR: 'País de residência',
  es_ES: 'País de residencia',
  de_DE: 'Heimatland',
  fr_FR: 'Pays de résidence',
};

export const COUNTRY_CODE_FIELD = {
  en_US: 'Country Code',
  it_IT: 'Prefisso Internazionale',
  pt_BR: 'Código do país',
  es_ES: 'Código del país',
  de_DE: 'Ländesvorwahl',
  fr_FR: 'Indicatif pays',
};

export const TELEPHONE_NUMBER_FIELD = {
  en_US: 'Telephone Number',
  it_IT: 'Recapito telefonico',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
  de_DE: 'Telefonnummer',
  fr_FR: 'Numéro de téléphone',
};

export const ACCESS_CODE_FIELD = {
  en_US: 'Access Code',
  it_IT: 'Codice di accesso',
  pt_BR: 'Código de acesso',
  es_ES: 'Código de acceso',
  de_DE: 'Zugangs Code',
  fr_FR: "Code d'accès",
};

export const PRIVACY_NOTICE_1 = {
  en_US: 'Privacy Notice.',
  it_IT: 'Privacy.',
  pt_BR: 'Aviso de Privacidade.',
  es_ES: 'Privacy Notice.',
  de_DE: 'Aviso de privacidad.',
  fr_FR: 'Politique de confidentialité.',
};

export const REGISTER_BUTTON = {
  en_US: 'Register',
  it_IT: 'REGISTRATI',
  pt_BR: 'Registrar',
  es_ES: 'Registrarse',
  de_DE: 'Registrieren',
  fr_FR: "S'ENREGISTRER",
};

export const MALE_LABEL = {
  en_US: 'Male',
  it_IT: 'Uomo',
  pt_BR: 'Masculino',
  es_ES: 'Hombre',
  de_DE: 'Männlich',
  fr_FR: 'Homme',
};

export const FEMALE_LABEL = {
  en_US: 'Female',
  it_IT: 'Donna',
  pt_BR: 'Feminino',
  es_ES: 'Mujer',
  de_DE: 'Weiblich',
  fr_FR: 'Femme',
};

export const SUBMIT_BUTTON = {
  en_US: 'SUBMIT',
  it_IT: 'INVIA',
  pt_BR: 'ENVIAR',
  es_ES: 'ENVIAR',
  de_DE: 'SENDEN',
  fr_FR: 'SOUMETTRE',
};

export const RELATION_TYPE_FIELD = {
  en_US: 'Relashionship',
  it_IT: 'Rapporto',
  pt_BR: 'Parentesco',
  es_ES: 'Relación',
  de_DE: 'Familienverhältnis',
  fr_FR: 'Lien de parenté',
};

export const ADD_NEW_BUTTON = {
  en_US: 'Add new',
  it_IT: 'Aggungi',
  pt_BR: 'Adicionar novo',
  es_ES: 'Agregar nuevo',
  de_DE: 'Hinzufügen',
  fr_FR: 'Ajouter',
};

export const REMOVE_BUTTON = {
  en_US: 'Remove',
  it_IT: 'rimuovere',
  pt_BR: 'remover',
  es_ES: 'retirar',
  de_DE: 'Abbrechen',
  fr_FR: 'Supprimer',
};

export const CONFIRMATION_TITLE = {
  es_ES: 'Confirmación',
  en_US: 'Confirmation',
  pt_BR: 'Confirmação',
  fr_FR: 'Confirmation',
  de_DE: 'Bestätigung',
  it_IT: 'Conferma',
};

export const SELECT_A_COUNTRY = {
  es_ES: 'Seleccione un país',
  en_US: 'Select a country',
  pt_BR: 'Selecione um pais',
  fr_FR: 'Choisissez un pays',
  de_DE: 'Wählen Sie ein Land aus',
  it_IT: 'Seleziona un Paese',
};

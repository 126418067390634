import React, { useRef, useEffect } from 'react';
import { Button } from '..';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as translations from './intl';
import * as userTerms from './intl.terms';
import { useTranslation } from '../../shared/hooks';

export default function TermsAndConditions({ showTerms, handleShowTerms }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { locale: intl } = useTranslation({
    translationsFile: translations,
  });
  const { locale: terms } = useTranslation({
    translationsFile: userTerms,
  });
  const { USER_TERMS, CLOSE } = intl;
  const { TERMS, TERMS_LIST } = terms;
  const TermsElementRef = useRef(null);

  useEffect(() => {
    if (showTerms) {
      const { current: descriptionElement } = TermsElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [showTerms]);

  return (
    <div>
      <Dialog
        onClose={handleShowTerms}
        open={showTerms}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        aria-labelledby="terms-and-conditions"
      >
        <DialogTitle id="terms-and-conditions" onClose={handleShowTerms}>
          {USER_TERMS}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText ref={TermsElementRef} tabIndex={-1}>
            {TERMS}
            <ol>
              {TERMS_LIST.map((el, index) => (
                <li key={index}>{el}</li>
              ))}
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleShowTerms}
            color="primary"
            text={CLOSE}
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
